import React from 'react'
import { useParams } from 'react-router-dom'
import Customer_header from './Customer_header'
import config from '../config'
import axios from 'axios'
import Header_nav from './Header_nav'
import Footer from './Footer';
import Swal from 'sweetalert2'
import Left_menu from './Customer/Left_menu'
import Header from './Customer/Header'
const Edit_customer_profile = () => {
    const customer_id = useParams()
    console.log("customer_id",customer_id)
    const[infostate,setInfoState]= React.useState({customer_info:[],isLoading:true})
    const [state, setState] = React.useState({ isLoading: true, user_info: {} , country_id:"" , state_id:"" , city_id:"" });
    console.log("state",state)
    const [stateSave, setSaveState] = React.useState({ uploadimg: ""});
    const [isImageSelected, setIsImageSelected] = React.useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
     React.useEffect(() => {
        getUsersInfo();
      }, []);

    // const getProfile = () => {
       
          
    //       let userDetail = localStorage.getItem('ship_rocket_user');
    //       console.log("userDetail====", config.UserId);
    //       let userData = {};
    //       if (userDetail != null) {
    //         userData = JSON.parse(userDetail)
    //       }
    //       setState({...state,customer_id:state.customer_id, full_name: userData.full_name, email: userData.email, mobile: userData.mobile,address: userData.address, image:userData.image , country:userData.country, state:userData.state , city:userData.city })

        
    //   }

     

      const handleChange =(e)=>{
        console.log("e", e);
   
      let userTemp = {...state.user_info};
      userTemp[e.target.name] =  e.target.value;
       setState({ ...state, user_info:userTemp });
    
      }

  const axios_get_api = () => {
    let sendData = state.user_info
    console.log("sendData == " , sendData)
  if(state.user_info.full_name!="" && state.user_info.email!='' && state.user_info.address!=' ' && state.user_info.mobile!=""   ){
      
    axios.post(config.apiUrl + `/user/customer_update`,  sendData , { headers: config.headers }).then((result)=>{
              
               console.log("result=== " , result)
                if (result.data.status==true) {
                   
                     localStorage.setItem('ship_rocket_user', JSON.stringify(result.data.output[0]));
                    console.log("---------------------------");
                     
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 2000
                      })
                      
                      Toast.fire({
                        background:"#e63900",
                        type: 'Success',
                        title: "Successfully Update",
                        color:"white"
                      });
                    
                    window.location.reload();
                   
                }
             
                    // setaddState({web_body_id:"",body_heading:"",pic:"right",body_sub_heading:"",body_img:"",body_content:"",body_link_title:""})
                
                
                }).catch((e) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 5000
                      })
                      
                      Toast.fire({
                        background:"#e63900",
                        type: 'error',
                        title: "Something Went Wrong",
                        color:"white"
                      });
                 
                  console.log("----error:   ", e);
                })
            
            }
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 2000
                  })
                  
                  Toast.fire({
                    background:"#e63900",
                    type: 'error',
                    title: "Please Fill the details",
                    color:"white"
                  });
            }
            
                

  };
       
  const getUsersInfo = () => {

    // setIsLoading(true)
    // let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_detail`;
    let sendData = {};

   
   // console.log("bb", sendData);
    axios.post(config.apiUrl + `/user/user_detail`,  sendData , { headers: config.headers }).then((res) => {
        // setIsLoading(false) 
        if(res.data.output.length ==1) {       
        setState({ ...state, user_info: res.data.output[0], isLoading: false })
        }



    }).catch((e) => {
        // setIsLoading(false)

    });
}
  
  return (
    <div >
  <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
   
   <div class=" width_custom bg-white p-3 mt-3">
            {/* <div class="text-center"> */}
                {/* <div class="figure-profile shadow my-4">
                    <figure> {state.image!=undefined && state.image !=""?  <img src={"https://cdn.shiprath.in/images/"+state.image}alt=""/>:
                        <img src="../../img/user1.png" alt="" className='img-fluid'/>}</figure>
                    <div class="btn btn-dark text-white floating-btn">
                        <i class="material-icons">camera_alt</i>
                        <input type="file" class="float-file" name="image"  onChange={(e) => handleChange(e)}/>
                    </div>
                </div> */}
            {/* </div> */}

            <h6 class="subtitle">Basic Information</h6>
            <div class="row mt-3">
                <div class="col-12 col-md-12 mb-3">
                    <div class="form-group float-label active">
                    <label class="form-control-label">Name</label>
                        <input type="text" class="form-control" name="full_name"  onChange={(e)=>handleChange(e)} required="" value={state.user_info.full_name}/>
                        
                    </div>
                </div>
                <div class="col-12 col-md-12 mb-3">
                    <div class="form-group float-label active">
                    <label class="form-control-label">Mobile Number</label>
                        <input type="tel" class="form-control" name= "mobile"   onChange={(e)=>handleChange(e)} required="" value={state.user_info.mobile!=undefined && state.user_info.mobile!="" ? state.user_info.mobile:"" }/>
                      
                    </div>
                </div>

                <div class="col-12 col-md-12 mb-3">
                    <div class="form-group float-label active">
                    <label class="form-control-label">Email</label>
                        <input type="text" class="form-control" name="email" onChange={(e)=>handleChange(e)} required="" value={state.user_info.email!=undefined && state.user_info.email!="" ? state.user_info.email:"" }/>
                        
                    </div>
                </div>
            </div>

            <h6 class="subtitle">Address</h6>

            <div class="row mt-3">
                <div class="col-12 col-md-12 mb-3">
                    <div class="form-group float-label active">
                    <label class="form-control-label">Address</label>
                        <input type="text" class="form-control" required="" name="address"  onChange={(e)=>handleChange(e)}  value={state.user_info.address!=undefined && state.user_info.address!=""? state.user_info.address:""}/>
                       
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <div class="form-group float-label active">
                        {/* <select class="form-control">
                            <option>United Sates</option>
                            <option>America</option>
                            <option>Africa</option>
                            <option>France</option>
                        </select> */}
                          <label class="form-control-label">Country</label>
                        <input type="text" class="form-control bg-light" required="" name="country"  onChange={(e) => handleChange(e)}value={state.user_info.country_name!=undefined && state.user_info.country_name!=""? state.user_info.country_name:""} readOnly/>
                      
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <div class="form-group float-label active">
                        {/* <select class="form-control">
                            <option>New York</option>
                            <option>America</option>
                            <option>Africa</option>
                            <option>France</option>
                        </select> */}
                        <label class="form-control-label">State</label>
                         <input type="text" class="form-control bg-light" required=""  onChange={(e) => handleChange(e)} name="state" value={state.user_info.state_name!=undefined && state.user_info.state_name!=""? state.user_info.state_name:""} readOnly/>
                        
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <div class="form-group float-label active">
                        {/* <select class="form-control">
                            <option>New York</option>
                            <option>America</option>
                            <option>Africa</option>
                            <option>France</option>
                        </select> */}
                         <label class="form-control-label">City</label>
                        <input type="text" class="form-control bg-light" required=""  onChange={(e) => handleChange(e)} name="city" value={state.user_info.city!=undefined && state.user_info.city!=""? state.user_info.city:""} readOnly/>
                       
                    </div>
                </div>
                <div class="col-12 col-md-12 mb-3">
                    <div class="form-group float-label active">
                    <label class="form-control-label">Update Password</label>
                        <input type="text" class="form-control" required="" name="password"  onChange={(e)=>handleChange(e)}  />
                       
                    </div>
                </div>
            </div>

            <br/>
            <div class="d-grid">
                <a  class="btn btn-lg btn-primary text-white btn-block btn-rounded" onClick={()=>axios_get_api()}><span >Update Profile</span></a>
            </div>
          
        </div>
      </div>
    </div>
  )
}

export default Edit_customer_profile
