import React from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Header_nav from './Header_nav';
import config from '../config';
import axios from 'axios';
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import Moment from 'react-moment';

const Tracking = () => {
  let tracking_number = useParams()
  // console.log("tracking_number === " , tracking_number)
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
 const [trackingstate,setTrackingState] = React.useState({track:{},isLoading:true})
 const [state, setState] = React.useState({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
 const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})



const dimension_detail = () =>{
  let full_api = config.apiUrl + `/user/get_company_info_api`;
  let sendData = {};

  axios.post(full_api, sendData, { headers: config.headers }).then(res => {
    setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
    // console.log("rnmmmmm", res);
  }).catch((e) => {
    // toast.configure()
     //toast.error("Some thing went wrong")
    // console.log("----error:   ", e);
  })
}

  React.useEffect(()=>{
    shipment_tracking()
  
    dimension_detail()
  },[])
    const shipment_tracking = () =>{
      
       let full_api = config.apiUrl + `/shipment/shipment_tracking`;
      let sendData = {customer_id:userData.customer_id , trackingNumber:tracking_number.tracking_no};
      // console.log("sendData",sendData)
       
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
       if(res.data.status==200){
          setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
          // console.log("res",trackingstate.track)
          setState({ ...state, shipmentDetail: res.data.ship_detail, isLoading:false})
        
       }
       else{
        setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
        // console.log("res",trackingstate.track)
        setState({ ...state, shipmentDetail: res.data.ship_detail, isLoading:false})
       }
      
       
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }
    
   
  
  return (
    <div>
      {/* <Header_nav/> */}
      <div class="sub_header_inner">
</div>
 
  {trackingstate.track!='' && state.shipmentDetail!='' ?
<div class="container">


{trackingstate.isLoading==false?



state.shipmentDetail.map((sub)=>(




 <section class="py-5 tracking_boxs_0 ">
  <div class="container w-md-50 ">
    <a href="/" class="mb-4" style={{textDecoration:"none"}}><i class="fa fa-chevron-left" aria-hidden="true"></i> Go back </a>
    <div class="text-center mt-3">
      <div class="status_xo">
        <h1>{trackingstate.track.shipment_status}</h1>
      </div>
      <div class="py-5">
        <small>Tracking ID</small>
        <h3>{sub.carrier_tracking_number}</h3>
      </div>
      <div class="d-flex justify-content-between">
          <div class="">
            <h4>{<Moment format="MMM DD YYYY hh:mm:ss a">
           { new Date(sub.created*1000)}
     </Moment>}</h4>
            <small>ORDER PLACED ON</small>
          </div>
          <div class="">
            <small>COURIER : {sub.service_provider}</small> <br/>
            <small>ORDER ID : {sub.shipment_id}</small>
          </div>
      </div>
    </div>
    {trackingstate.track.history.length>0?
    
       
    <div class="row justify-content-center py-5">
    <div class="col-12 col-md-8">
      <div class="box_cont tracking_xb_0">
        <div class="box-header">
          Status Delivery
        </div>
        <hr/>
           {trackingstate.track.history.map((sub)=>(
            <div class="progress-container position-relative">
            <div class="step-container">
            <div class="step active">
                <i class="material-icons-outlined">radio_button_checked</i>
            </div>
            <div class="progress"></div>
            <div class="step-info">
                <h4>{sub.message} </h4>
                <p>{sub.location}</p>
                <p><Moment format="MMM DD YYYY hh:mm:ss a">
                 {(sub.time)}
     </Moment></p>
            </div>
            </div>
          
        </div>

           ))}
        
        </div>
      </div>
    </div> 
             
       
         


    
     :<div className='' style={{marginLeft:"50px"}}><b>Tracking History Not Found</b></div>}     
  </div>
</section>



))
:<ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} />}    
</div>
:
<div className='row' style={{marginTop:"200px"}}>
<div className='col-4'></div>
<div className='col-4'>
 <h2  style={{marginLeft:"50px"}}>Tracking Number Not Found </h2>
 
 <a   style={{marginLeft:"150px"}}href='/' className='btn btn-danger text-white'>Go Back to Home </a>
</div>
<div className='col-4'></div>

</div>}

 
    </div>
   

    


  )
}

export default Tracking



