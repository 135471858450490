import React from 'react'
import Customer_header from './Customer_header'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Footer from './Footer'
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../config"
import Moment from 'react-moment';
import Header_nav from './Header_nav';
import download from 'downloadjs'

const My_orders = () => {
    let navigate = useNavigate()
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const[recordid,setRecordid] = React.useState({record_id:""})
    const [uploadModalState, setUploadModalState] = React.useState({ show: false,excel_fle:"" });
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const[state,setState]= React.useState({shipment_list:[],isLoading:true})
    const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
    const [shipSelect, setShipSelect] = React.useState({ shipment_id: "",cancel_reason:"" });
    const [modalState, setModalState] = React.useState({ show: false });
    const [modaldtdtcState, setModaldtdcState] = React.useState({ show: false });
    const[dtdc , setdtdc] = React.useState();
    const[loadingstate , setLoadingstate] = React.useState(false)
    const[summarystate , setSummarystate] = React.useState(false)



    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 5000
  })

    // console.log("shipmentState",shipmentstate)
    React.useEffect(() => {
  
        shipment_list()
        dimension_detail()
    
      }, [])

    const shipment_list=(status)=>{
        let dataToSend = {customer_id : userData.customer_id ,  status:status
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/customer_orders';
     
     // // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            setState({shipment_list:res.data.output,isLoading:false})
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }

    const dimension_detail = () =>{
      let full_api = config.apiUrl + `/user/get_company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }

  
    const cancelShipment = () =>{
      if(shipSelect.cancel_reason != ""){
      let full_api = config.apiUrl + `/shipment/shipment_cancel`;
      let sendData = {customer_id:userData.customer_id,shipment_id:shipSelect.shipment_id,cancel_reason:shipSelect.cancel_reason};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        Toast.fire({
          background: "#10b93b",
          type: 'success',
          title: res.data.message,
          color: "white"
      });
        if(res.data.status == "success"){

                setShipSelect({cancel_reason:"",shipment_id:""});
                setModalState({ show: false })
                shipment_list()
        }
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }else {
      Toast.fire({
        background: "#10b93b",
        type: 'success',
        title: "Enter Reason to Cancel.",
        color: "white"
    });
    }
    }

    const inputHandleChange = (e) => {
      const value = e.target.value;
      const text = e.target.name;
      setShipSelect({ ...shipSelect, [text]: value })
    }
    
   const shipping_label =(sub)=>{
    // console.log("sub ==== " , sub)
    if(sub.carrier_id=="1656377059"){
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://dtdcapi.shipsy.io/api/customer/integration/consignment/shippinglabel/stream?reference_number='+sub.carrier_tracking_number,
      responseType: 'blob',
      headers: { 
        'Content-Type': 'application/pdf', 
        'api-key': 'e96fb203eef06314303c1aa7ef8c0c'
      }
    };
    
    axios.request(config)
    .then((response) => {
      // console.log((response.data));
      download(response.data, 'dtdc.pdf')
      
    })
    .catch((error) => {
      // console.log(error);
    });
  
  }
   }

   const csvinputEditHandleChange = (e) => {        
    if (e.target.type == "file") {            
        setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
    } else {
        setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.value });
    }
  }
  const uploadOrdersFile= () => {
    setLoadingstate(true)
    if (uploadModalState.excel_fle != undefined && uploadModalState.excel_fle != '') {
       // var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        let fileName = uploadModalState.excel_fle[0].name;
       // // console.log("fileName =======>                 ",fileName);
       
       // // console.log("file =======>                 ",fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0]);
       // return 
        if(fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv"){
            let full_api = config.apiUrl + `/shipment/upload_bulk_orders`;
     let bodyFormData = new FormData()
    // // console.log("bb", editState);
    
     bodyFormData.append("customer_id",  userData.customer_id)
    

    
    bodyFormData.append("excel_fle", uploadModalState.excel_fle[0]);
     
   // let bodyData = {country_id:country_id,csvFile:uploadModalState.csv_fle}
    // console.log("bb", bodyFormData);
    axios.post(full_api, bodyFormData, {
        headers:config.headers
    }).then((res) => {
        // console.log("res ", res);

        // setUploadModalState({...uploadModalState, show: false });
      //  setEditState({ ...editState, state_name: "", state_id: "" })

       
        if (res.status == 200 && res.data.status == "success") {
          setLoadingstate(false)
          setSummarystate(true)
           setRecordid({record_id:res.data.record_id})
           // setTimeout(getStateList(0,true), 5000);
           const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
        })
            Toast.fire({
                background: "#206bc4",
                type: 'success',
                title: "Successfully Inserted",
                color: "white"
            });
            // shipment_list()
            // setTimeout(()=>{
            //   navigate(`/import_process/${product_id}/${carrier_id}/${source_city_id}`)
            // },1000)
            
        }
        
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',

            })
        }

        // navigate('/categories')
        // let newCategory = res.data.category;
        //setState(state.concat([newCategory]))
    }).catch((e) => {

        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',

        })
    });
}else {
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
})
    Toast.fire({
        background: "#8a2be2",
        type: 'error',
        title: "Please choose CSV file!",
        color: "white"
    });
}
}else {
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
})
         Toast.fire({
                background: "#10b93b",
                type: 'error',
                title: "Please choose a file!",
                color: "white"
            });
}


}
const closeModal =()=>{
  setUploadModalState({...uploadModalState,show:false})
  shipment_list()
}
   
  return (

<div>
<Modal show={modalState.show} onHide={() => setModalState({ show: false })}>
<Modal.Header closeButton>
                    <Modal.Title>Do you really want to Cancel ?</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <div className="row">
                      <div className="col-2 mb-3 mt-5" style={{marginLeft:"25px"}}>
                      <i class="fa fa-envelope " style={{fontSize:"20px",marginTop:"10px"}} aria-hidden="true"></i>
                      </div>
                        <div className="col-md-10 mb-3 mt-5 " style={{marginLeft:"-50px"}}>
                              <input className="form-control" id="first_name" type="text" placeholder="Enter Reason" name="cancel_reason" onChange={(e) => inputHandleChange(e)} required   style={{ background: "transparent", border: "none", borderBottom: "1px solid #000000",  outline:"none", boxShadow:"none"}}/>
                         </div>
                         
                         {/* <div className="col-2 mb-1 mt-3" style={{marginLeft:"25px"}}>
                         <i class="fa fa-lock " style={{fontSize:"20px",marginTop:"10px"}} aria-hidden="true"></i>
                      </div> */}
                         
                       <div>
                       
                    <button className="btn mt-3" style={{color: "#fff", backgroundColor: "#D65142", display:"inline-block",textAlign:"center",verticalAlign:"middle",position:"relative",boxSizing:"border-box",marginLeft:"190px",padding: "0.84rem 2.14rem",
    fontSize: "18px",fontWeight:"bold"}} onClick={() =>
      cancelShipment()
                        }>Yes</button>
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
               
                  <Button variant="secondary" onClick={() => setModalState({ show: false })}>
                        Close
                    </Button>
                              
                </Modal.Footer>
            </Modal>

            
  <Header_nav/>
<div class="sub_header_inner">
</div>

{state.isLoading==true?
        <ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} />:
        <section class="py-4">
  <div class="container">

    <div class="card mb-3">
      <div class="card-body">
        <header class="">
            <div class="row mb-4 mt-3">
                <div class="col col-md">
                    <div class="d-flex">
                        <div class="">
                            <h4><a href=""><i class="material-icons">chevron_left</i></a> My Orders</h4>
                        </div>
                        <div class="ps-2 pt-2"><h6 class="text-muted">({state.shipment_list.length} Shipments)</h6></div>
                    </div>
                    
                </div>
                <div class="col col-md-2 mb_display_none">
                    <div class="d-grid">
                        <a href="/ship_1" class="btn btn-main btn-block"> Create Order</a>
                    </div>
                </div>
                <div class="col col-md-2 mb_display_none">
                    <div class="d-grid">
                        <a class="btn btn-main btn-block" onClick={()=>setUploadModalState({...uploadModalState,show:true})}>Import Bulk Orders</a>
                    </div>
                </div>
                <Modal show={uploadModalState.show} onHide={()=>setUploadModalState({...uploadModalState,show:false})}>
               
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <ModalTitle><h4 class="modal-title">Import Bulk Orders </h4></ModalTitle>
        
        <button type="button" class="btn-close" onClick={()=>closeModal()}></button>
      </div>
      <div class="modal-body">
        <div class="alert alert-success">
          <strong>Alert!</strong> 
          <ul>
            <li>This action can not be undone.</li>
            <li>Doing the corrupt or broken variable file can cause system malfunction.</li>
            <li>Download sample file and setup the file in correct format to import</li>
          </ul>
        </div>
        <div class="d-flex justify-content-between">
            <div class="">Download Sample File</div>
            <div class="ps-2 "><a href="https://cdn.shiprath.in/sample-template/bulk-order-template.csv" target="_blank"  class="btn btn-outline-main btn-sm">Downlaod Sample File</a></div>
        </div>
        <hr/>
        <div class="mb-3 mt-3">
          <label for="" class="form-label">File: <span class="text-danger">*</span></label>
          <input type="file" class="form-control" id="" placeholder="Enter Banner"  required="" name="excel_fle" onChange={(e) => csvinputEditHandleChange(e)} />
        </div>
      </div>
      <div class="modal-footer">
       {loadingstate==false && summarystate==false? <a class="btn btn-main btn-block" onClick={() =>uploadOrdersFile()} >Submit</a>:loadingstate==true && summarystate==false?<ReactLoading type={"spin"} color={"#dc291e"}  height={40} width={40} />:summarystate==true?<a class="btn btn-primary btn-block" href={`/summary/${recordid.record_id}`} >View Summary</a>:""}
        {/* <button type="button" class="btn btn-main btn-block" onClick={()=>closeModal()}>Close</button> */}
      </div>
    </div>
  </div>

</Modal>
            </div>
            
        </header>
      </div>
    </div>

    

    <div class="card ">
      <div class="card-body">
      {/* <section class="py-3">
            <div class="row">
                <div class="col-12 col-md">
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                          <a className={(shipmentstate.shipment_status == null) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(null)}>All</a>
                        </li>
                        <li class="nav-item">
                          <a className={(shipmentstate.shipment_status == 1) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(1)}>Booked</a>
                        </li>
                        <li class="nav-item">
                          <a className={(shipmentstate.shipment_status == 2) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(2)}>Picked Up</a>
                        </li>
                        <li class="nav-item">
                          <a className={(shipmentstate.shipment_status == 3) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(3)}>In Transit</a>
                        </li>
                        <li class="nav-item">
                          <a className={(shipmentstate.shipment_status == 4) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(4)}>Out For Delivery</a>
                        </li>
                        <li class="nav-item">
                          <a className={(shipmentstate.shipment_status == 5) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer"}} onClick={() => shipmentFilter(5)}>Delivered</a>
                        </li>
                        <li class="nav-item">
                          <a className={(shipmentstate.shipment_status == 6) ? 'nav-link active' : 'nav-link'} style={{cursor:"pointer",}} onClick={() => shipmentFilter(6)}>Cancelled</a>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-4">
                    <input type="text" class="form-control search_o" placeholder="Search"/>
                </div>
            </div>      
        </section> */}

        <table class="table  shipment_box table-responsive">
            <thead>
              <tr class="custom_row_mb">
                <th>Order Number</th>
                <th>Origin</th>
                <th>Destination</th>
                <th>Sender</th>
                <th>Receiver</th>
                <th>Name of product</th>
                <th></th>
                
              </tr>
            </thead>
            <tbody>
                {state.shipment_list.map((sub)=>(
                <tr class="custom_row_mb">
                    <td>
                        
                        <h2><a >#{sub.order_number}</a></h2>
                        <small class="text-muted">
                            {/* <Moment format="DD MM YYYY"> */}
                        {(sub.order_date)}
            {/* </Moment>   */}
            </small>
                    </td>
                    {sub.sender.map((item)=>(
                    <td>

                        <h5>{item.pincode} - {item.city}</h5>
                        <small class="text-muted"> {item.country}</small>
                    </td>
                    ))}
                    {sub.receiver.map((i)=>(
                    <td>
                        <h5>{i.pincode} - {i.city}</h5>
                        <small class="text-muted">{i.country}</small>
                    </td>
                    ))}
                    {sub.sender.map((item)=>(
                    <td>
                        <h5>{item.name}</h5>
                    </td>
                    ))}
                     {sub.receiver.map((i)=>( 
                    <td>
                        <h5>{i.name}</h5>
                    </td>
                    ))}
                   
                    <td>
                    {sub.items.map((sub,index)=>(
                        <h5> {sub.item_name}<small class="text-muted"></small> </h5>))}
                        <small class="text-muted">Total Item - {sub.items.length}</small><br/>
                        <small class="text-muted">Total Weight - {sub.total_weight} ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</small>
                    </td>
                    
                    { sub.shipment_id==""?<td><button className='btn btn-main btn-sm' onClick={()=> navigate({pathname:`/package_list`,
       search: `sender_name=${sub.sender[0].name}&sender_address=${sub.sender[0].address}&sender_country=${sub.sender[0].country}&sender_country_code=${sub.sender[0].country_code}&sender_postal_code=${sub.sender[0].pincode}&sender_state_name=${sub.sender[0].state}&sender_city=${sub.sender[0].city}&sender_address_id=${sub.address_id}&sender_city_id=${sub.sender[0].city_id}&sender_mobile=${sub.sender[0].mobile}&sender_email=${sub.sender[0].email}&receiver_name=${sub.receiver[0].name}&receiver_email=${sub.receiver[0].email}&receiver_mobile=${sub.receiver[0].mobile}&receiver_address=${sub.receiver[0].address}&receiver_country=${sub.receiver[0].country}&receiver_country_code=${sub.receiver[0].country_code}&receiver_postal_code=${sub.receiver[0].pincode}&receiver_city=${sub.receiver[0].city}&receiver_state_name=${sub.receiver[0].state}&return_name=${sub.return[0].name}&return_email=${sub.return[0].email}&return_mobile=${sub.return[0].mobile}&return_address=${sub.return[0].address}&return_country=${sub.return[0].country}&return_country_code=${sub.return[0].country_code}&return_postal_code=${sub.return[0].pincode}&return_city=${sub.return[0].city}&return_state_name=${sub.return[0].state}&mode=${sub.mode}${sub.items.map((i)=>(`&items=${i.item_value},${i.item_name},${i.item_quantity}`))}&shipment_type=${sub.type}&insurance=${sub.insurance_amount}&order_id=${sub.order_number}&order_date=${sub.order_date}${sub.items.map((it)=>(
        `&dimensions=${it.item_height},${it.item_width},${it.item_length},${it.item_weight}`
        ))}&cod_amount=${sub.cod_amount}&payment_mode=${sub.payment_mode}`
     })}>Book Now</button></td>:""}
                </tr>
                ))}
                
            </tbody>
          </table>
      </div>
    </div>

         
  </div>
</section>
}
<Footer/>
</div>
  )
}

export default My_orders
