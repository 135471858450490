import React, {  useRef } from 'react'
import Header from './Header'
import Left_menu from './Left_menu'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Barcode from 'react-barcode';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import html2canvas from 'html2canvas';
import BarcodeComponent from './BacodeComponent'
import { renderToString } from 'react-dom/server';
import createRoot from 'react-dom';
import { useNetworkState } from 'react-use';
import { BarcodeIcon, CornerUpLeft, CornerUpLeftIcon, FileDown, Truck } from 'lucide-react';
import Footer from '../Footer';
const Product_list = () => {

    const[state , setState]= React.useState([])
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
React.useEffect(()=>{
    product_listing()
},[])
    const product_listing = () =>{
        let full_api = config.apiUrl + `/user/product_list`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
            setState(res.data.output)
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }

  return (
    <div>
       <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
     
     
       
         
            

            <div class="row mb-2">
                <div class="col-12 col-md">
                    <div class="d-flex">
                        <div class="back_btn_wrap">
                            <a href="" class="back_btn">
                           <CornerUpLeft/>
                            </a>
                        </div>
                        <div class="">
                            <h2>Product Listing </h2>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5 text-end btns">
                <a href="/customer/add_product" class="btn btn-primary">Add Product</a>
                </div>
            </div>

            
            <section>
            <div class="content-wrapper-data">
                    <div class="card border-0">
                    <div class="card-body">
                            <div class="table-data table-responsive">
                                <table class="table table-hover table-responsive">
                                    <thead>
                                    <tr class="table-light">
                                        <th>Date</th>
                                        <th>Image</th>
                                        <th>SKU #</th>
                                        <th>Product Name</th>
                                        <th>Category</th>
                                        <th>Value</th>
                                        <th>Total Stock</th>
                                        {/* <th>Status</th>
                                        <th>Action</th> */}
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {state.length>0?
                                        state.map((sub)=>(
                                        <tr class="ship-now-container pro_listl">
                                            <td>
                                                <h6>{sub.created}</h6>
                                            </td>
                                            <td>
                                            <a href=""><img src={"https://cdn.shiprath.in/customer_assets/"+sub.product_image} height="20px" width="70px" /></a>
                                            </td>
                                            <td>
                                                <h3><a href={`/customer/product_detail/${sub.product_id}`}>{sub.sku_code}</a></h3>
                                            </td>
                                            <td>
                                                <h4>{sub.product_name}</h4>
                                               {sub.variants.map((item)=>( <h6> {item.value}</h6>))}
                                            </td>
                                            <td>
                                            {sub.category_name}
                                            </td>
                                            <td>
                                               {sub.price}
                                            </td>
                                            <td>
                                                <h4></h4>
                                            </td>
                                            {/* <td>
                                                <div class="form-check form-switch  mb-2">
                                                   <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                                </div>
                                            </td> */}
                                            {/* <td>
                                                <a href="#" class="btn btn-secondary text-white btn-sm me-2">Edit</a>
                                                <a href="#" class="btn btn-secondary text-white btn-sm">Delete</a>
                                            </td> */}
                                        </tr>
                                       )) :""}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            


           

                

     
      
    </div>
    <Footer/>
    </div>
  )
}

export default Product_list
