import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'

import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import Footer from '../Footer'

const Billing_shipping_charges = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const [state,setState] = React.useState([])
    const[loading, setloading] = React.useState(true)
  const[balancestate,setbalanceState]= React.useState({total_balance:[]})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    React.useEffect(()=>{
        list(0,true)
       
    },[otherStates.rowsPerPage])

    const list = (index=0,onLoad)=>{

        let dataToSend = {customer_id : userData.customer_id , limit:otherStates.rowsPerPage,indexValue:index};
    
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/user/shipping_charges_transaction';
        axios.post(url, dataToSend, { headers: config.headers })
            .then((res) => {
              // console.log("responseJson => ", res);
              if(res.data.status==true){
                if( onLoad) {
                  setOtherState({...otherStates,total_count:res.data.count ,  page:index})         
    
      }
       setState(res.data.output)
       setloading(false)
       }
      else{
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
            })
            Toast.fire({
              background:"rgb(231, 91, 75)",
              type: 'Unsuccess',
              title: "Something Went Wrong !",
              color:"white"
            });
      } })
          
            .catch((error) => {
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
                })
                Toast.fire({
                  background:"rgb(231, 91, 75)",
                  type: 'Unsuccess',
                  title: "Something Went Wrong !",
                  color:"white"
                });
                // console.log(error);
            });    
    }
    
    const handlePageChange = (event,newPage) =>{
      setOtherState({...otherStates,page:newPage})
      // console.log("newPage ", newPage);
      // getCountryList(newPage);
      list(newPage,true)
  }
  const handleChangeRowsPerPage = (event , newPage) => {
      // console.log("event ", event.target.value);
      setOtherState({ ...otherStates, rowsPerPage: event.target.value })
       
  }
     
      
      
    //   useEffect(() => {
    
    //     if(userDetail!= null) {
    //       let uDetail = JSON.parse(userDetail)
    //       setUserState({full_name:uDetail.full_name})
    //       config.UserId = uDetail.customer_id
    //     }
    //   }, [])
      

  return (
    <div>
          {/* <?php include 'meta.php';?> */}
    
    {/* <?php include 'left_menu.php';?> */}
    <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
        
<Billing_nav/>
        {/* <?php include 'billing_nav.php';?>     */}
        
        {/* <section class="mb-3">
          <div class="container">
            <div class="card border-0">
              <div class="card-body">
                <div class="row states stboxbil py-3">
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded  ">
                            <h5>&#8377;0.00</h5>
                            <small>Total Freight Charges</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Billed Freight Charges</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Unbilled Freight Charges</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Total On-Hold Amount</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Invoice Due Amount</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Engage Charges</small>
                        </div>
                    </div>
                </div>
              </div>
            </div>     
          </div>
        </section> */}

        <section class="mb-3">
          <div class="container">
            {/* <div>
                <div class="input-group mb-3 input-group-sm">
                    <input type="text" class="form-control" placeholder="Search by Order ID"/>
                    <button class="btn btn-primary" type="submit">Search</button>
                  </div>
            </div> */}
            <div class=" table-responsive">
            {loading? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"50px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
    </div>:
                <table class="table table-bordered table_box billing_table">
                    <thead>
                      <tr>
                        <th>Transaction ID</th>
                        <th>AWB Number</th>
                        <th>Weight & Zone</th>
                        <th>Carrier</th>
                        <th>Status</th>
                        <th>Credit</th>
                        <th>Debit</th>
                       
                      </tr>
                    </thead>
                 
                    <tbody>
                        {state.length>0?
                        state.map((sub)=>(
                     <tr>
                        <td>{sub.transaction_id}<br/><Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> - <Moment format="hh:mm:ss a">
                        {new Date(sub.created*1000)}
            </Moment></td>
                        <td>{sub.carrier_tracking_number}</td>
                        <td>{sub.total_weight} KG<br/>
                        {sub.zone_name?sub.zone_name:""}</td>

                        <td>{sub.service_provider}</td>
                        <td>{sub.status==6? "Refunded":"Shipping Charges"}</td>
                        <td className='text-success'>{sub.status==6 ?<>₹ {sub.total_amount}</> :""}</td>
                        <td className='text-danger'> {sub.status!=6 && sub.status!=0?<>- ₹ {sub.total_amount}</> :""}</td>
                        </tr>
                     )) : 
                     <tr>
                     <td colspan="12" class="text-center">
                         Sorry! There is no data available at the moment.
                     </td>
                   </tr>}
                    </tbody>

                </table>
}  
            </div>
          </div>
        </section>
        <TablePagination
                component="div"
                rowsPerPageOptions={[20,25,30,35,40,45,50]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />
       
    </div>
    <Footer/>
    </div>
  )
}

export default Billing_shipping_charges
