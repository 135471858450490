import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import moment from 'react-moment';
import download from 'downloadjs'
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import Footer from '../Footer';
import * as XLSX from 'xlsx'

const Customer_invoice = () => {
    let navigate = useNavigate()
    const[state , setState ] = React.useState([])
    const[isLoading , setisLoading] = React.useState(true)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[stateshipments , setShipments] = React.useState([])
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    React.useEffect(()=>{
        invoice_list()
    },[])
    const invoice_list=(status,index=0,onLoad)=>{
        let dataToSend = {
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/customer_invoices';
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
           
            setState(res.data.output)
            setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }
    // const next_step = (sub)=>{
    //     navigate({
    //       pathname: '/customer/invoice_vendor',
    //       search: `invoice_id=${sub.invoice_id}&invoice_date=${sub.invoice_date}&amount=${sub.amount}`
    //     })
    //   }
      const next_step = (sub) => {
        const url = `/customer/invoice_vendor?invoice_id=${sub.invoice_id}&invoice_date=${sub.invoice_date}&amount=${sub.amount}`;
        
        // Open the URL in a new tab
        window.open(url, '_blank');
      };
      const get_shipments = (invoice_id)=>{
        let dataToSend = {
invoice_id:invoice_id
        };
          let url = config.apiUrl + '/shipment/get_invoice_shipments';
           axios.post(url, dataToSend, { headers: config.headers })
              .then((res) => {
              
                setShipments(res.data.output)
                downloadExcel(res.data.output)
              })
              .catch((error) => {
                 
              });    
     } 
     const transformDataForExcel = (data) => {
        return data.map(item => {
          console.log("created == ", item.created)
          const formattedDate = item.created
            ? new Date(item.created * 1000).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
            : '';
    
          const formattedTime = item.created
            ? new Date(item.created * 1000).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })
            : '';
          return {
            'Tracking Id': item?.carrier_tracking_number || '',
            'Reference Number': item?.order_number || '',
            'Carrier': item?.carrier_name || '',
            'Mode':  item?.product_type_name || '',
            'Carrier Product': item?.service_provider || '',
            'Current Status': getStatusText(item?.status) || '',
           
          }
        });
      };
    
      const getStatusText = (status) => {
        // Define your status mappings here
        const statusMappings = {
          0: 'Pending',
          10: 'RTO',
          1: 'Pick Up Awaited',
          2: 'Picked Up',
          3: 'In Transit',
          4: 'Out For Delivery',
          5: 'Delivered',
          6: 'Cancelled',
          7: 'Failed',
          8: 'NDR',
          9: 'Pickup Scheduled',
          15:'Booked'
    
        };
    
    
        return statusMappings[status] || '';
      }

      const downloadExcel = (items) => {
        const data = transformDataForExcel(items);
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Invoice AWb');
        XLSX.writeFile(wb, 'Invoice AWb.xlsx');
      };
  return (
    <div>
    <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
    
           <div className=''>
        <section class="mt-3">
        <div class="row mb-3">
            <div class="col-12 col-md-4">
                <h5>Customer Invoices</h5>
            </div>
            <div class="col-12 col-md">
            </div>
        </div>
        {isLoading? 
                        <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"60px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
                        <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
                        </div>:
            <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                <table class="table table-hover  shipm_tablew">
                    <thead>
                        <tr>
                            <th class="border-gray-200">Invoice No. #</th>
                            <th class="border-gray-200">Customer</th>
                            <th class="border-gray-200">Invoice Date</th>
                            <th class="border-gray-200">Invoice Period</th>
                            <th class="border-gray-200">Invoice Amount</th>
                            <th class="border-gray-200">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                       
                        {state.map((sub)=>(

                       
                        <tr>
                            <td>
                            <strong>{sub.invoice_id}</strong>
                            </td>  
                            <td>
                                {sub.company_name?sub.company_name:sub.full_name?sub.full_name:""}
                            </td>                      
                            <td>
                                {sub.invoice_date}
                            </td>  
                            <td>
                               {sub.invoice_period}
                            </td>
                            <td>
                                ₹{sub.amount?sub.amount:0}
                            </td>
                            <td class="fw-700">
                                {/* <a href="assets/doc/shipment_report.csv" class="btn btn-outline-primary "><i class="fa fa-file-excel-o" aria-hidden="true"></i></a> */}
                                <a onClick={(e)=>get_shipments(sub.invoice_id)} class="btn btn-primary me-2"><i class="fa fa-file-excel-o" aria-hidden="true"></i></a>
                                <a  onClick={()=>next_step(sub)} class="btn btn-primary"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
                            </td>
                        </tr>
                         ))}
                    </tbody>
                </table>
            </div> 
}

    </section>
    </div>               

  </div>


{/* <?php
include('footer.php');
?>   */}
<Footer/>
    </div>
  )
}

export default Customer_invoice
